@import "./styles/spacing";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  min-width: 100%;
  overflow-y: scroll;
}

#root {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: #000000;
  text-decoration: none;
}